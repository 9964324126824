import React from "react";
import Banner from "../components/Banner";
import WhatServiceDo from "../components/service/WhatServiceDo";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `Rapid Prototyping Company`,
    content:
      "Leveraging the latest technologies, our rapid prototyping company accelerates your product development journey, enabling you to test, refine, and validate designs before full-scale production.",
  };
  const whatServiceContent = {
    title: "What do <span class='h1-span'>Rapid Prototyping Companies do?</span>",
    content: [
      "Rapid Prototyping Companies are an indispensable part of the product development process.",
      "They assist businesses in transforming their ideas into tangible prototypes by leveraging modern technologies, streamlining the prototyping process, and using agile methodologies.",
      "These agencies mitigate risk and help achieve sustainable business growth by offering rapid prototyping services.",
      "Being one such rapid prototyping agency, we have the expertise to bring your ideas to life by offering a range of prototyping services.",
    ],
    img: ["what-service-image-rapidprototype.webp"],
    imageTitle: "Rapid Prototyping",
    imageAlt: "",
  };
  const commonContent = {
    title: "Who needs Rapid Prototyping Agency?",
    para: "UX Rapid Prototyping is invaluable for businesses seeking to gather user feedback and refine their ideas quickly and cost-effectively before full product development.",
    img: ["who-needs-rapidprototype.webp"],
    imageTitle: "",
    imageAlt: "",
    content: [
      {
        title: "Healthcare Companies",
        text: "Companies looking for technological advances and accelerating their products to the market for better user experience.",
      },
      {
        title: "Telecommunication Companies",
        text: "Companies looking for a faster way to connect with their audience by offering digital products based on their needs and preferences.",
      },
      {
        title: "Consumer Companies",
        text: "Companies who want to speed up their product development process and look for validation for improved customer satisfaction.",
      },
      {
        title: "Supply-chain Companies",
        text: "Companies looking to streamline their operations and optimize their product offering to reduce time and cost of production.",
      },
    ],
  };

  const commonContent2 = {
    title:
      "Why Work With Rapid Prototyping Company?",
    para: "Rapid Prototyping Company can promptly turn your ideas into innovative, tangible products. Their prototyping services empower you to iterate early and make informed decisions, resulting in quality offerings and reduced risks.",
    img: ["what-are-benefits-rapidprototype.webp"],
    imageTitle: "Rapid Prototyping Services",
    imageAlt: "",
    content: [
      {
        title: "Accelerated Product Development",
        text: "Rapid Prototyping Services streamlines the process and use flexible methods to speed up the product development process to seize market opportunities. ",
      },
      {
        title: "Continuous Design Improvement",
        text: "Rapid Prototyping assists in constantly refining and enhancing solutions based on digital market dynamics and users’ preferences.",
      },
      {
        title: "Mitigates Risk",
        text: "Rapid Prototyping Services implements robust strategies to address the challenges and ensure smooth project execution for a competitive advantage.",
      },
      {
        title: "Customer-Centric Approach",
        text: "Rapid Prototyping streamlines the process and uses flexible methods to speed up product development and seize market opportunities.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why Opt Octet as Your <span>Rapid Prototyping Agency?</span>",
    linkText: ``,
    para: `We are a leading <a href="/" target="_blank" rel="noreferrer">UI UX design agency</a> that delivers high-quality prototypes that represent your vision. Our services empower you to transform concepts into tangible models quickly, gather user feedback, and refine your designs.`,
    img: ["why-opt-for-rapidprototype.webp"],
    imageTitle: "Benefits of Rapid Prototyping",
    imageAlt: "",
    content: [
      {
        title: "Collaborative Approach",
        text: "We value collaboration and work closely with your internal team to combine expertise and shared feedback for exceptional results.",
      },
      {
        title: "Scalable Solutions",
        text: "We focus on long-term growth strategies and offer scalable solutions to your digital product that can accommodate evolving market trends.",
      },
      {
        title: "Advanced Technology",
        text: "We deliver innovative solutions leveraging cutting-edge technology that improves user experience and drives sustainable growth.",
      },
      {
        title: "Assured Quality",
        text: "We conduct rigorous quality checks by testing and validating our design solutions to deliver high-quality, flawless, functional products.",
      },
    ],
  };
  const commonContent4 = {
    title: "How do we offer Rapid <span>Prototyping Services?</span>",
    para: "Focusing on a collaborative and transparent approach, we work closely with you to understand your business goals and project requirements and incorporate them into our UX rapid prototyping.",
    img: ["how-we-conduct-rapidprototype.webp"],
    imageTitle: "Rapid Prototyping Agency",
    imageAlt: "",
    content: [
      {
        title: "1. Goals of the Project",
        text: "We start with the goal of the product in mind by defining why it is intended to be built.",
      },
      {
        title: "4. Know the type of Device",
        text: "We also consider the nature of the product, such as a website or mobile app, and the devices it will support.",
      },
      {
        title: "2. Identify the target audience",
        text: "We strive to understand demographics perfectly to create products that serve their needs well.",
      },
      {
        title: "5. Consider the Deliverables",
        text: "We understand what we need to deliver to stay on the right track.",
      },
      {
        title: "3. Study the Competiton",
        text: "We study the competitors to know where the product stands and what unique value it offers.",
      },
    ],
  };
  const commonContent5 = {
    title: "Why invest in Rapid Prototyping Agency?",
    para: "Investing in rapid prototyping agencies accelerates your product's development process, minimizes risk, and enhances overall efficiency in this fast-paced virtual ecosystem.",
    content: [
      {
        title: "25% Less Time-to-Market",
        text: "Companies that utilize rapid prototyping services get to introduce their digital products to market faster, potentially gaining a competitive edge.",
      },
      {
        title: "20% Increased Efficiency",
        text: "Rapid prototyping reduces the time required to refine and optimize a digital product, leading to increased design efficiency.",
      },
      {
        title: "30% Reduced Development Cost",
        text: "Rapid Prototyping identifies and rectifies the design flaws early in the process, reducing development costs and rework.",
      },
    ],
    desc: "**These data are from public sources; we do not claim to owe these stats. ",
  };
  const cta = {
    title: "Unlock Innovation With <span class='h1-span'>Rapid Prototyping UX!</span>",
  };
  const cta2 = {
    title: "Enhance Your Design With <span>UX Rapid Prototyping! </span>",
  };
  const cta3 = {
    title: "Let’s prototype and start <br/> your innovative journey!",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "Hala Gig",
      desc: "A Consumer Experience Platform",
      Industry: "SaaS",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/ui-ux-design-halagig/",
      images: ["hala-gig.webp", "hala-gig-2.webp"],
      firstAlt: "",
      firstTitle: "Feedback Collection Screen",
      secondAlt: "",
      secondTitle: "Agent's Account",
    },
    {
      title: "Auto recruits",
      desc: "A Job-Portal Platform",
      Industry: "SaaS",
      WhatWeDid: [
        {
          title: "Product Development",
          link: "/product-development/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/ui-ux-design-auto-recruits/",
      images: ["auto-recruits.webp", "auto-recruits-2.webp"],
      firstAlt: "",
      firstTitle: "Landing Page",
      secondAlt: "",
      secondTitle: "Applicant's Screen",
    },
  ];

  const Questions = [
    "What Rapid Prototyping tools do you use?",
    "Do you share prototypes along with the design?",
    "Can your Rapid Prototyping Services identify early design flaws?",
    "How much time do you take for Rapid Prototyping?",
    "What is the cost of your Rapid Prototyping Services?",
  ];
  const clientsTitle =
    "Companies Who Trust Our <span class='h1-span'>Rapid Prototyping Services</span>";
  const faqDes = `We understand that you might have more questions about our rapid prototyping services. In this section, we aim to clarify your queries and help you guide towards innovation. However, if you still have any doubts, please <a href="/contact-us/" title='contact us'> contact us</a>.`;

  const faqData = [
    {
      para: [
        `Our rapid prototyping tools are Figma and Adobe XD, which help us collaborate with your in-house team.`,
      ],
    },
    {
      para: [
        `We work closely with your in-house team during the rapid prototyping process to select the methodologies and tools to convert ideas into life.`,
        `Hence, we share prototypes and the design to deliver an exceptional user experience.`
      ],
    },
    {
      para: [
        `As a leading rapid prototyping agency, we can detect early design flaws and issues in the development process.`,
        `Through prompt prototyping, our designers can detect functionality and make improvement strategies for the digital product.`,
        `We create prototypes that closely resemble the final product to ensure smooth testing and product development.`,
        `By offering our rapid prototyping services, we reduce risk, minimize the cost, and provide high-quality final products.`
      ],
    },
    {
      para: [
        `Our rapid prototyping company's time requirements depend on factors such as the scope and complexity of the project, the number of screens involved, the technologies used, etc.`,
        `At our rapid prototyping agency, we do not compromise on quality and align it with our client goals.`,
        `Hence, the time frame may range from a few days to weeks. However, it may change depending on the level of collaboration involved, the number of iterations, etc.`
      ],
    },
    {
      para: [
        `The cost of our rapid prototyping services varies depending on certain factors, such as the complexity of your project, the technologies used, the duration, and your business requirements.`,
        `At Octet, we believe in a transparent approach and offer competitive pricing by delivering high-quality rapid prototyping. However, we request that you <a href="/contact-us/" target="_blank" rel="noreferrer">contact us</a> for a detailed quotation and accurate cost estimation.`,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "How are ReactJs Development Services different from Angular and VueJs Development Services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "ReactJs, Angular, and VueJs are all part of the Java Script framework but have different features.
            ReactJs solutions build reusable UI Components focusing on unidirectional data flow. In contrast, Angular provides a comprehensive set of features, such as two-way data binding, and follows MVC architecture.
            VueJs is a progressive framework that balances the other two frameworks and offers its features."
                }
              },{
                "@type": "Question",
                "name": "Do you offer customized ReactJs Development Services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer customized and the best ReactJs Development Services for your project requirements.
            We have a team of experienced ReactJs Developers who can create a robust, functional, and interactive interface for you to simplify your complex offerings.
            We collaborate closely with you and seek regular feedback to ensure that our services align with your business goals."
                }
              },{
                "@type": "Question",
                "name": "Can your React js app development company integrate third-party libraries and UI Frameworks?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We can seamlessly integrate third-party libraries and other UI frameworks in ReactJs projects.
            We have the expertise and experience to incorporate other UI frameworks, such as Bootstrap, to enhance your applications.
            We aim to deliver a functional and interactive application that exceeds your expectations."
                }
              },{
                "@type": "Question",
                "name": "How does ReactJs Development Services enhance user experience?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "With our ReactJs Development Services, we offer visually interactive and responsive interfaces that work on all platforms and browsers.
            Our Reactjs Developers optimize your application's performance through code splitting, lazy loading, and other methods and integrate third-party libraries for better functionality. 
            We aim to enhance your user experience and provide a path for sustained business growth."
                }
              },{
                "@type": "Question",
                "name": "What is the duration and cost of your ReactJs Services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "As the best Reactjs Development Company in India, we understand that every business has unique requirements that require different durations to fulfill.
            Hence, the duration and charges vary based on factors such as business goals, project requirements, and customizations needed.
            We believe in a transparent approach and provide an accurate timeline and quotation for your customized services.
            We aim to deliver a high-quality service with a cost-effective solution to ensure sustained business growth. Hence, we request that you contact us for a detailed project discussion."
                }
              }]
            }                         
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <WhatServiceDo data={whatServiceContent} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
              Projects By Our <span className="h1-span">Rapid Prototyping Company</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection
                  num={index}
                  lastLength={projectData}
                  data={data}
                />
              </div>
            ))}
          </section>
          <Testimonials title={'Client’s Take on Our Solutions'} />
          <Cta data={cta2} />
          <CommonServiceComponent
            data={commonContent5}
            condition
            mode={"light"}
          />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq
            Questions={Questions}
            section
            des={faqDes}
            link
            faqData={faqData}
          />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title="Rapid Prototyping Company | UX Rapid Prototyping"
    description="Our rapid prototyping company turn concepts into reality with cost-effective solutions. Accelerate your product development with our rapid prototyping services."
  />
);
